<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Categories Groups'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Categories Group Edit'"
      :title="'Categories Group Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">SEO Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'seoName'"
              :label="'seoName'"
              :type="'text'"
              :value="formDataToSend.seo_name"
              :placeholder="'SEO name'"
              :error-messages="validationErrors['seoName']"
              readonly
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import categoriesApi from "~/api/categories";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "CategoriesGroupEdit",
  metaInfo: {
    title: "Categories Group Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
  },
  computed: {
    ...mapState("categories", ["error", "categoriesGroup", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getItemCategoriesGroup();
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name != undefined ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name != undefined ? this.formDataToSend.seo_name.trim() : "",
        status: this.formDataToSend.status ? 1 : 0,
      };
      const url = `/${this.getId}`;
      this.$store.dispatch("categories/editCategoriesGroup", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItemCategoriesGroup() {
      const url = `/${this.getId}`;
      try {
        categoriesApi.getItemCategoriesGroup(url).then((res) => {
          this.$store.commit("categories/setError", {});
          this.$store.commit("categories/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.seo_name = data.seoName;
          this.formDataToSend.status = data.status ? true : false;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
